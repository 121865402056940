<template>
  <div class="match">

    <div class="content">
      <div class="content_one" style="margin:auto; background:#4342ff">
        <img :src="info?info.introduction:''" />
      </div>
      <div class="content_one">
        <!-- <h2>大赛主题</h2> -->
        <img :src="info?info.theme:''" />
      </div>
      <div class="content_one">
        <a id="top"></a>
        <!-- <h2>奖项设置</h2> -->
        <div class="award"><img :src="info?info.award:''" /></div>
      </div>
      <div class="content_one">
        <!-- <h2>评委阵容</h2> -->
        <div class="award"><img :src="info?info.judges:''" /></div>
      </div>
      <div class="content_one">
        <!-- <h2>获奖权益</h2> -->
        <div class="award"><img :src="info?info.related_equity:''" /></div>
      </div>
      <div class="content_one">
        
        <!-- <h2>参赛规则</h2> -->
        <div class="award"><img :src="info?info.contest_know:''" /></div>
      </div>
      <div class="content_one">
        <!-- <h2>模板下载</h2>
        <div class="btn" @click="download">
            点击下载参赛模板
        </div> -->
        <div class="award"><img :src="info?info.process:''" /></div>
      </div>
      <div class="content_one">
        <!-- <h2>作品要求</h2> -->
        <div class="award"><img :src="info?info.description:''" /></div>
      </div>
   
      <div class="content_one">
        <a id="guize"></a>
        <!-- <h2>条款细则</h2> -->
        <div class="award"><img :src="info?info.other:''" /></div>
      </div>
       
    </div>
  


  </div>
</template>

<script>
export default {
  name: "match",
  data() {
    return {
      info: null,
      events_id:''
    };
  },
  mounted() {
    this.events_id=this.$route.query.events_id;
    this.getInfo();
    
    // if(this.$route.query.anchor){
    //   setTimeout(()=>{
    //     this.point2(this.$route.query.anchor);
    //   },100)
      
    // }
  },
  methods: {
    getInfo() {
      this.$api.POST(this.$face.match_info, { id: 1 }, (res) => {
        this.info = res.data;
      });
    },
    
    download() {
      
      window.open(this.info.event_file);
    },
    point2(e) {
      var odiv = document.getElementById(e);
      console.log(odiv);
      let s =this.getDistanceOfLeft(odiv).top;
      console.log(s);
      let space = 50;
      if (top >= 1000 && top < 2000) {
        space = 50;
      } else if (top >= 2000 && top < 3000) {
        space = 80;
      } else if (top >= 3000 && top < 4000) {
        space = 110;
      } else if (top >= 4000 && top > 6000) {
        space = 150;
      } else if (top >= 6000 && top > 10000) {
        space = 180;
      }
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      const timeTop = setInterval(() => {
        let height=top += space;
        if(height>s){
            height=s;
        }
        document.body.scrollTop =
          document.documentElement.scrollTop = height;
        if (top >= s) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    getDistanceOfLeft(obj) {
      var left = 0;
      var top = 0;
      while (obj) {
        left += obj.offsetLeft;
        top += obj.offsetTop;
        obj = obj.offsetParent;
      }
      return {
        left: left,
        top: top,
      };
    },
  },
  
};
</script>

<style scoped>
.match {
  background-color: #fff;
  min-height: 6800px;
}
.banner {
  width: 100%;
}
.banner img {
  width: 100%;
}
.nav {
  height: 106px;
  width: 100%;
  background: url(../../assets/images/match/nav_bg.jpg) center center no-repeat
    #2ef493;
}
.nav ul {
  width: 1430px;
  margin: auto;
  background: url(../../assets/images/match/nav2.jpg) center center no-repeat
}
.nav ul li {
  float: left;
  font-size: 28px;
  text-align: center;
  line-height: 106px;
  cursor: pointer;
}
.nav ul li a{ color: #000;}
/* .nav ul li:nth-child(1) {
  width: 270px;
}
.nav ul li:nth-child(2) {
  width: 224px;
}
.nav ul li:nth-child(3) {
  width: 250px;
}
.nav ul li:nth-child(4) {
  width: 227px;
}
.nav ul li:nth-child(5) {
  width: 242px;
}
.nav ul li:nth-child(6) {
  width: 217px;
} */
.content {
  background: #0000fe;
  overflow: hidden;
}
.content .content_one {
  margin: 30px auto 0;
  text-align: center;
  align-items: center;
  align-content: center;
  position: relative;
}
.content .content_one img{margin: auto; display: inline-block !important; margin-left: 50%; transform: translateX(-50%);}

.content_one h2 {
  font-size: 80px;
  color: #ede8e2;
  font-weight: bold;
}
.content_one a{
  position: absolute;top:-120px;
}
.award {
  clear: both;
  overflow: hidden;
  margin: 0 auto 0;
}
.btn{padding: 20px 38px 22px; background: #fff; display: inline-block; margin: 50px 0; font-size: 40px; color: #3833d7; font-weight: normal; border-radius: 50px; cursor: pointer;}

.float_left{ position: fixed; top: 35%; left: 0; width: 206px; height: 300px; z-index: 9; background: #3ad9ff; border-radius: 15px;}
.float_div{ width: 168px; height: 160px; background: #b1f504; border-radius: 15px; margin: 47px auto 0; position: relative;}
.float_div_text{ position: absolute;left: 16px; height: 50px; width: 135px; line-height: 50px; top:-25px; background: #eb88f4; border-radius: 50%; text-align: center;font-size: 30px; font-weight: lighter;}
.float_div_day{ font-size: 80px; text-align: center; padding-top: 45px;}
.float_div_unit{ position: absolute; right:5px; bottom: 5px; font-size: 18px;}
.float_div_title{ font-size: 40px; text-align: center; margin-top: 25px;}
.float_right{position: fixed; top: 35%; right: 0; width: 206px; height: 300px; z-index: 9; background: #3ad9ff; border-radius: 15px;}
.float_right_img{ margin: 28px auto 12px; }
.float_right_img img{ margin: auto;}
.float_right_text{ font-size: 18px; line-height: 25px; text-align: center;}
#guize{ position: absolute; top: 350px;}
</style>